<template>
  <div class="w-100">
    <div class="overflow-auto" style="max-height: 400px" ref="point-wrapper">
      <div class="d-flex justify-content-start py-2">
        <h5>포인트 내역</h5>
      </div>
      <b-table-simple
          v-if="!pending"
      >
        <b-thead>
          <b-tr>
            <b-th>_id</b-th>
            <b-th>내역</b-th>
            <b-th>POINT</b-th>
            <b-th>DATE</b-th>
            <b-th>METHOD</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="paymentHistory.length">
          <b-tr
              v-for="h in paymentHistory"
              :key="`payment-history-${h._id}`"
              :ref="`payment-history-${h._id}`"
          >
            <b-td>
              <div style="font-size:0.6rem;">
                {{ h._id }}
              </div>
            </b-td>
            <b-td>
              {{ h.title }}
            </b-td>
            <b-td>
              <div>
                SAM POINT: {{ h.samPoint }}
              </div>
              <div>
                BONUS: {{ h.bonusPoint }}
              </div>
              <div>
                DEDUCTION: {{ h.deductionPoint }}
              </div>
            </b-td>
            <b-td>
              {{ h.regDate }}
            </b-td>
            <b-td>
              {{ h.status }}
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else>
          <b-tr>
            <b-td colspan="5">
              NO DATA
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div
        v-else
        class="d-flex justify-content-center"
      >
        <b-spinner
            variant="danger"
        />
      </div>
    </div>
    <hr />
    <div
      v-if="!pending"
      class="d-flex justify-content-end mb-4"
    >
      <b-form
        inline
        v-if="mode"
      >
        <b-form-input
            type="number"
            v-model="point"
        />
      </b-form>

<!--      <b-button-group class="ml-1" v-if="!mode">
        <b-button
            squared
            size="sm"
            v-on:click="mode = 'ADD'"
        >
          <font-awesome-icon
              icon="dollar-sign"
          />
          지급
          <font-awesome-icon
              icon="plus"
              class="mr-2"
          />
        </b-button>
        <b-button
            squared
            size="sm"
            v-on:click="mode = 'REMOVE'"
        >
          <font-awesome-icon
              icon="dollar-sign"
          />
          회수
          <font-awesome-icon
              icon="minus"
              class="mr-2"
          />
        </b-button>
      </b-button-group>
      <b-button-group v-else>
        <b-button
            squared
            size="sm"
            v-on:click="save"
        >
          <font-awesome-icon
              icon="save"
              class="mr-2"
          />
        </b-button>
        <b-button
            squared
            size="sm"
            v-on:click="cancel"
        >
          <font-awesome-icon
              icon="dollar-sign"
          />
          취소
          <font-awesome-icon
              icon="times"
              class="mr-2"
          />
        </b-button>
      </b-button-group>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  props: {
    selectedId: String
  },
  data: () => ({
    pending: false,
    page: 1,
    history: [],
    point: 0,
    mode: null
  }),
  computed: {
    paymentHistory () {
      return this.history.map(( h ) => {
        return {
          ...h,
          paid_amount: new Intl.NumberFormat('ko-KR', {style: 'currency', currency: 'KRW'}).format(h.paid_amount),
          regDate: new Date(h.regDate).toLocaleString()
        }
      })
    }
  },
  created () {
    (async () => {
      console.log(this.selectedId)
      await this.load()
    })()
  },
  methods: {
    async load () {
      this.pending = true
      try {
        const { data } = await this.axios({
          url: `/admin/payment-history/${this.selectedId}`,
          method: 'GET',
          params: {
            page: this.page
          }
        })
        const { result, history } = data
        if (result) this.history = history
      } catch (e) {

      }
      this.pending = false
    },
    async save () {
      if (!confirm(`포인트 처리를 하시겠습니까?`)) return
      this.pending = true
      try {
        const { data } = await this.axios({
          url: `/admin/point/${this.selectedId}`,
          method: 'PUT',
          data: {
            point: this.point,
            mode: this.mode
          }
        })
        const { result, insertedId, error } = data
        if (result) {
          await this.load()
          await this.$emit('load')
          await this.$nextTick()
        } else {
          this.$bvToast.toast(`${error}`, {
            title: 'ERROR',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false
          })
        }
        // const target = this.$refs[`payment-history-${insertedId}`]
        // target.scrollIntoView()

      } catch (e) {

      }
      this.pending = false
    },
    cancel () {
      this.point = 0
      this.mode = null
    },
    async addPoint () {
      console.log('포인트 지급')
    },
    async removePoint () {
      console.log('포인트 회수')
    },
    async cancelPayment (_id) {
      if (!confirm(`${_id}건을 취소하시겠습니까?`)) return
      console.log(`결제 취소? ${_id}`)
    }
  }
}
</script>

<style scoped>
  th, td {
    font-size: 0.8rem;
    vertical-align: middle;
  }
</style>
